<template>
<Alert :isActive="isError" v-if="isError">{{errorMessage}}</Alert>
<Loading :isShow="isLoading" v-if="!isError">
  <Navigation></Navigation>
  <div class="theme-container">
    <PageTitle
      :title="pageTitle"
      :imgSrc="headerImage"
    ></PageTitle>
    <template v-if="featuredEvent.event">
      <div class="section-container p-0">
        <div class="container">
          <div class="description-container">
            <h1> {{featuredEvent.title}} </h1>
          </div>
          <div class="section-container no-bottom-padding">
            <FeaturedEventCard
              :titleImgSrc="featuredEventCard.titleImgSrc"
              :title="featuredEventCard.title"
              :date="featuredEventCard.date"
              :formatted-title="featuredEventCard.formattedTitle"
              :description="featuredEventCard.description"
              :list-title="featuredEventCard.listTitle"
              :listContent="featuredEventCard.listContent"
            />
          </div>
        </div>
      </div>
    </template>
    <div class="section-container p-0">
      <div class="container">
        <div class="section-container"> 
          <div class="description-container">
            <h1> {{sectionTwo.title}}</h1>
            <div class="mt-4" v-html="sectionTwo.description"></div>
          </div>
        </div>
        <div v-for="event in events" :key="event.title">
          <div class="section-container"> 
            <EventCard
              :title="event.title"
              :subtitle="event.subtitle"
              :date="event.date"
              :description="event.description"
              :imgSrc="event.imgSrc"
              :link="event.link"
              :flipImage="event.flipImage"
              :showReadMoreLink="event.showReadLink"
              :style="'image-top'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section-container-no-bg">
      <div class="container">
        <Card
          :title="sectionThree.title"
          :content="sectionThree.description"
          :imgSrc="sectionThree.imgSrc"
          :flipImage="true"
        />
      </div>
    </div>
  </div>
  <BottomNavigator :id="specialEventsPageId"/>
</Loading>
</template>

<script>
import BottomNavigator from "@/components/BottomNavigator.vue"
import Card from "@/components/Card.vue";
import FeaturedEventCard from "@/components/FeaturedEventCard.vue";
import EventCard from "@/components/EventCard.vue";
import Navigation from "@/components/Navigation.vue";
import PageTitle from "@/components/PageTitle.vue";
import Loading from '@/components/Loading.vue';
import Alert from '@/components/Alert.vue';
import api from '@/services/api.js';

export default {
  data: () => ({
		isLoading: false,
		isError: false,
		errorMessage: '',
    events: [],
    pageTitle: "",
    headerImage: "",
    featuredEvent: {
      title: "",
    },
    featuredEventCard: {
      titleImgSrc: "",
      title: "",
      date: "",
      formattedTitle: "",
      description: "",
      listTitle: "",
      listContent: [],
    
    },
     sectionTwo: {
        title: "",
        description: ""
      },
       sectionThree: {
        title: "",
        description: "",
        imgSrc: "",
        flipImage: false
      }
	}),
  computed: {
    specialEventsPageId() {
      const specialEventsPage = this.$store.state.pages.specialevents;
      if(specialEventsPage){
        return specialEventsPage.id;
      }
      return null;
    },
  },
	methods: {
		async getData() {
      const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' }
			this.isError = false;
			this.isLoading = true;
			try {
				const { data } = await api.getSpecialEventsPage(this.specialEventsPageId)
        this.pageTitle = data.pageTitle;
        this.headerImage = data.pageIcon;
        this.featuredEvent = data.featuredEvent;
        if(data.featuredEvent.event) {
          this.featuredEventCard.titleImgSrc = data.featuredEvent.event.image;
          this.featuredEventCard.title = data.featuredEvent.event.title;
          this.featuredEventCard.date = (new Date(data.featuredEvent.event.date)).toLocaleDateString('en-US', dateOptions);
          this.featuredEventCard.formattedTitle = data.featuredEvent.event.emphasizedText ? data.featuredEvent.event.emphasizedText.text : "";
          this.featuredEventCard.description = data.featuredEvent.event.description? data.featuredEvent.event.description.text : "";
          this.featuredEventCard.listTitle = "Highlights";
          this.featuredEventCard.listContent = data.featuredEvent.event.highlights.map((highlight) => (highlight.text));
        }
        this.sectionTwo.title = data.events.title;
        this.sectionTwo.description = data.events.subtitle.text;
        this.sectionThree.title = data.closingItem.event.title;
        this.sectionThree.description = data.closingItem.event.previewText? data.closingItem.event.previewText.text : "";
        this.sectionThree.imgSrc = data.closingItem.event.image;
        this.sectionThree.flipImage = (data.events.eventList.length + 1) % 2 == 0 ? true : false;
        this.events = data.events.eventList.map((evnt, index) => ({
          title: evnt.event.title,
          subtitle: evnt.event.subtitle,
          date:(new Date(evnt.event.date)).toLocaleDateString('en-US', dateOptions),
          description: evnt.event.previewText?  evnt.event.previewText.text : "",
          flipImage: (index % 2 == 0 ? false : true),
          link: `/special-events/all-events/${index}`,
          imgSrc: evnt.event.image,
          showReadLink:evnt.showReadLink
        }));
			} catch (err) {
				this.isError = true;
				this.errorMessage = err.message;
			} finally {
				this.isLoading = false;
			}
		}
	},
  watch:{ 
    specialEventsPageId(newValue, oldValue) {
      if (newValue) {
        this.getData()
      }
    }
  },
	async beforeRouteUpdate(to, from, next) {
		//await this.getData();
		next();
	},

	async created() {
    if(this.specialEventsPageId){
		  await this.getData();
    }	
  },

  components: {
    BottomNavigator,
    EventCard,
    Navigation,
    PageTitle,
    Loading,
    Card,
    FeaturedEventCard,
    Alert,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.theme-container {
  margin-bottom: 5%;
  @include relative;
  :deep(.page-title) {
    .title-container{
      background-color: $primary-color-3;
    }
  }
}

.section-container {
  @include padding(7 10 7 10 );
  background-color: $secondary-color-4;
  margin-top: -20px;
  z-index: 1;
  @include responsive(phone) {
    @include padding(2 1 2 1);
  }
  @include responsive(tablet) {
    @include padding(4 5 4 5);
  }
}

.no-bottom-padding{
  @include padding(5 10 0 10 );
  @include responsive(phone) {
    @include padding(0 1 0 1);
  }
  @include responsive(tablet) {
    @include padding(0 5 0 5);
  }
}

.section-container-no-bg {
  @include padding(14 10 14 10 );
  z-index: 1;

  @include responsive(phone) {
    @include padding(2 1 2 1);
  }
  @include responsive(tablet) {
    @include padding(4 5 4 5);
  }
  :deep(h1)  {
    color: $font-color-1;
  }
}

.description-container {
  @include padding(10 10 2 10);
  background-color: $secondary-color-4;
  margin-top: -20px;
  z-index: 1;
    @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(4 5 4 5);
  }
}

h1 {
  color: $primary-color-2;
  font-size: 42px;
}

</style>
