<template>
  <v-template v-if="style=='image-top'">
    <img :src="imgSrc" alt="" class="list-card-image" />
    <div class="main-content-container">
      <v-template v-if="subtitle">
        <h3 class="formatted-title" v-html="subtitle"></h3>
      </v-template>
      <h1 class="pb-5">{{ title }}</h1>
      <!--<h3 class="category">{{ date }}</h3>-->
      <div v-html="description"></div>
      <h3 class="list-title">{{ listTitle }}</h3>
      <a style="text-decoration: none" v-if="showReadMoreLink" :href="link">
            Read More
            <img class ="arrow" src='@/assets/images/next-arrow-red.svg' />
        </a>
    </div>
  </v-template>
  <v-template v-if="style=='image-side'">
    <div class="flex-container rectangleCard-container">
      <div class="flex-6 mx-3 mx-md-0 align-self-center img-container" v-if="flipImage">
        <img :src="imgSrc" alt="Slalom White Logo" class="card-image" />
      </div>
      <div :class="`flex-6 ${flipImage ? 'ps-lg-5' : 'pe-lg-5'} pt-5 pt-lg-3 content`">
          <h3 class = "subtitle">{{ subtitle }}</h3>
          <h1 >{{ title }}</h1>
          <!--<h3 class = "date">{{ date }}</h3>-->
          <div  class="mt-3" v-html="description"></div>
          <a style="text-decoration: none" v-if="showReadMoreLink" :href="link">
              Read More
              <img class ="arrow" src='@/assets/images/next-arrow-red.svg' />
          </a>
      </div>
      <div class="flex-6 mx-3 mx-md-0 align-self-center img-container" v-if="!flipImage" >
        <img :src="imgSrc" alt="Slalom White Logo" class="card-image"/>
      </div>
    </div>
  </v-template>
</template>

<script>
export default {
  props: [
    "title",
    "subtitle",
    "date",
    "description",
    "link",
    "imgSrc",
    "flipImage",
    "showReadMoreLink",
    "style"
  ],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.main-content-container {
  background-color: $secondary-color-9;
  @include padding(10);
  border-radius: 8px 8px 56px 8px;
  margin-top: -5%;
  @include responsive(phone) {
    @include padding(5 4 5 4);
  }
  @include responsive(tablet) {
    @include padding(10 5 10 5);
  }
}

.list-card-image {
  @include border-radius(2);
  max-width: 95%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height:454px;
}

h1 {
  font-size: 42px;
}

h1,
h3 {
  color: $primary-color-2;
  margin-top: 3%;
}

.custom-list {
  padding-left: 2%;
}

.category {
  color: $secondary-color-8;
  @include margin(2 0 4 0);
}

.list-title {
  color: $primary-color-2;
  font-size: 28px;
  margin-bottom: 1em;

}

.formatted-title {
    color: $secondary-color-8;
    @include margin(2 0 2 0);
    font-size: 24px;
    line-height: 28.8px;
}
</style>
