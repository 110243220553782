<template>
  <img :src="titleImgSrc" alt="" class="list-card-image" />
  <div class="main-content-container">
    <h1>{{ title }}</h1>
    <!--<h3 class="category">{{ date }}</h3>-->
    <v-template v-if="formattedTitle">
      <h3 class="formatted-title" v-html="formattedTitle"></h3>
    </v-template>
    <div v-html="description"></div>
    <h3 class="list-title">{{ listTitle }}</h3>
    <ul class="custom-list" v-for="item in listContent" :key="item">
      <li v-html="item"></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: [
    "titleImgSrc",
    "title",
    "date",
    "formattedTitle",
    "description",
    "listTitle",
    "listContent",
  ],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.main-content-container {
  background-color: $secondary-color-9;
  @include padding(10);
  border-radius: 8px 8px 56px 8px;
  margin-top: -5%;
  @include responsive(phone) {
    @include padding(5 4 5 4);
  }
  @include responsive(tablet) {
    @include padding(10 5 10 5);
  }
}

.list-card-image {
  @include border-radius(2);
  max-width: 95%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height:454px;
}

h1 {
  font-size: 42px;
}

h1,
h3 {
  color: $primary-color-2;
  margin-top: 3%;
}

.custom-list {
  padding-left: 2%;
}

.category {
  color: $secondary-color-8;
  @include margin(2 0 4 0);
}

.list-title {
  color: $primary-color-2;
  font-size: 28px;
  margin-bottom: 1em;

}

.formatted-title {
    color: $secondary-color-8;
    @include margin(2 0 4 0);
    font-size: 24px;
    line-height: 28.8px;
}
</style>
