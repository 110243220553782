<template class="template">
  <Alert :isActive="isError" v-if="isError">{{ errorMessage }}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
    <Navigation></Navigation>
    <div class="theme-container">
      <PageTitle :title="pageTitle" :imgSrc="headerImage"></PageTitle>
      <template v-for="project in projects">
        <!--<div v-if="project.splashMedia">
          <video v-if="isVideo(project.splashMedia)" :src="project.splashMedia" controls></video>
          <img v-else :src="project.splashMedia" />
        </div> -->
        <div class="section-container">
        <div class="container">
          <ListCard
            :titleImgSrc="project.topPicture"
            :listCategory="project.subtitle"
            :subtitle="project.sections.title"
            :title="project.title"
            :project-goal-description="project.sections.content.text"
            :lists="project.lists"
            :optionalImgSrc="project.bottomPicture"
            :featuredFiles="project.featuredFiles"
          />
          <template v-if="project.metrics.length > 0 && project.metrics[0].title">
            <CardGrid :cards="project.metrics" />
          </template>
          </div>
        </div>
      </template>
    </div>
    <BottomNavigator :id="spotlightPageId" />
  </Loading>
</template>

<script>
import api from "@/services/api.js";
import Alert from "@/components/Alert.vue";
import BottomNavigator from "@/components/BottomNavigator.vue";
import Card from "@/components/Card.vue";
import CardGrid from "@/components/CardGrid.vue";
import ListCard from "@/components/ListCard.vue";
import Loading from "@/components/Loading.vue";
import Navigation from "@/components/Navigation.vue";
import PageTitle from "@/components/PageTitle.vue";

export default {
  data: () => ({
    isLoading: false,
    isError: false,
    errorMessage: "",
    pageTitle: "",
    headerImage: "",
    projects:[],
  }),
  computed: {
    spotlightPageId() {
      const spotlightPage = this.$store.state.pages.projectspotlight;
      if(spotlightPage){
        return spotlightPage.id;
      }
      return null;
    },
  },
  methods: {
    isVideo (srcUrl) {
      const fileExtension = srcUrl.split('.').pop();
      switch(fileExtension){
        case 'ogg':
        case 'mp4':
        case 'mov':
          return true;
        default:
          return false;
      }
    },

    async getData() {
      this.isError = false;
      this.isLoading = true;
      try {
        const { data } = await api.getSpotlightPage(this.spotlightPageId);        
        this.pageTitle = data.pageTitle;
        this.headerImage = data.pageIcon;
        this.projects = data.projects.map(project => ({
          ...project,
          metrics: project.metrics.map(metric => ({
            title: metric.value,
            subtitle: metric.text,
          })),
          lists: project.lists.map(
            list => ({
              ...list,
              items: list.items.map(item => item.text)
            })
          )
        }));
      } catch (err) {
        this.isError = true;
        this.errorMessage = err.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch:{
    spotlightPageId(newValue, oldValue) {
      if (newValue) {
        this.getData()
      }
    }
  }, 

  async beforeRouteUpdate(to, from, next) {
    next();
  },

  async created() {
    if(this.spotlightPageId){
		  await this.getData();
    }  
  },

  components: {
    BottomNavigator,
    PageTitle,
    Navigation,
    Loading,
    Card,
    ListCard,
    CardGrid,
    Alert,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.theme-container {
  :deep(.page-title) {
    .title-container {
      background-color: $primary-color-2;
    }
  }
}

.section-container {
  @include padding(10);
  background-color: $secondary-color-4;
  z-index: 1;
  &:last-child{
    margin-bottom:100px;
  }
  @include responsive(phone) {
    @include padding(10 1 10 1);
  }
  @include responsive(tablet) {
    @include padding(10 5 10 5);
  }
}
</style>
