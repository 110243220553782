<template>
  <img v-if="titleImgSrc" :src="titleImgSrc" alt="" class="list-card-image" />
  <div class="main-content-container">
    <h1>{{ title }}</h1>
    <h3 class="category">{{ listCategory }}</h3>
    <h3 class="mb-4">{{ subtitle }}</h3>
    <div v-html="projectGoalDescription"></div>
     <div v-html="projectGoalDescription2"></div>
    <div v-for="item in textSection" :key="item.title">
      <h3>{{ item.title }}</h3>
      <div>{{ item.body }}</div>
    </div>
    <template v-for="list in lists">
    <h3>{{ list.title }}</h3>
    <div class="pt-3">
      <ul class="custom-list" v-for="item in list.items" :key="item">
        <li class="d-flex">
          <div class="list-icon"><img :src="list.listIcon"></div>
          <div class="list-text">{{ item }}</div>
        </li>
      </ul>
    </div>
    </template>
    <div class="d-flex flex-column">
      <div class="mt-5" v-for="fileObject in featuredFiles">
        <div v-if="fileObject.previewImage">
          <a v-if="fileObject.file" :href="fileObject.file" download>
            <img :src="fileObject.previewImage" alt="" class="list-card-optional-image" />
          </a>
          <img v-if="!fileObject.file" :src="fileObject.previewImage" alt="" class="list-card-optional-image" />
        </div>
        <div class="">
          <template v-if="fileObject.file">
            <a :href="fileObject.file" download>{{fileObject.title||fileObject.file}}</a>
          </template>
          <template v-if="!fileObject.file">
            <span v-if="fileObject.title">{{fileObject.title}}</span>
          </template>
        </div>
      </div>
    </div>

    <img v-if="optionalImgSrc" :src="optionalImgSrc" alt="" class="list-card-optional-image" />
    <a style="color: inherit;" :href="link">
      {{ linkName }}
      <img class="arrow" src="@/assets/images/next-arrow.svg" v-if="linkName"/>
    </a>
  </div>
</template>

<script>
export default {
  props: [
    "titleImgSrc",
    "title",
    "listCategory",
    "projectGoalDescription",
    "projectGoalDescription2",
    "textSection",
    "listTitle",
    "listContent",
    "optionalImgSrc",
    "subtitle",
    "link",
    "linkName",
    "lists",
    "featuredFiles"
  ],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.main-content-container {
  font-size:18px;
  background-color:$secondary-color-9;
  @include padding(10);
  border-radius: 8px 8px 56px 8px;
  margin-top: -5%;
  @include responsive(phone) {
    @include padding(5 4 5 4);
  }
  @include responsive(tablet) {
    @include padding(10 5 10 5);
  }
}

.arrow {
  margin-left: 10px;
}

.list-card-image {
  @include border-radius(2);
  max-width: 95%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align:center;
  max-height:454px;
}

.list-card-optional-image {
  max-width: 100%;
  display: block;
  margin-top: 20px;
}

h1 {
  font-size: 42px;
}

h3 {
  font-size: 24px;
}

h1,
h3 {
  color: $font-color-2;
  margin-top: 3%;
}

.custom-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: auto;
  padding-bottom:20px;
}

.custom-list li {
  font-size: 18px;
  .list-icon{
    padding-right:1rem;
  }
  .list-text{
    padding-top:3px;
  }
}

.category {
  color: $secondary-color-8;
  font-size: 28px;
  @include margin(2 0 4 0);
}
</style>
